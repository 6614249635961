<template>
  <div :class="['accordion',{'accordion--border-none' : border === 'none'}]">
		<div class="accordion__header" @click="setOpen()">
			<Heading size="5" margin="none">{{label}}</Heading>
			<div class="accordion__trigger">
				<Icon icon="add" v-if="!isOpen" size="small" />
				<Icon icon="remove" v-if="isOpen" size="small" />
			</div>
		</div>
		<div :class="['accordion__body',{'accordion__body--open' : isOpen}]">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		label: String,
		border: String,
	},
  data() {
    return {
			isOpen: false,
		}
  },
	methods: {
		setOpen(){
			this.isOpen = !this.isOpen;
		},
    setClose() {
      this.isOpen = false
    },
	}
}

</script>

<style lang="scss" scoped>
.accordion{
	padding: var(--accordion-padding);
	width: var(--accordion-width);
	border-top: var(--accordion-border);
}
.accordion--border-none{
	border-top: none;
}
.accordion__header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: var(--accordion-header-padding);
	cursor: pointer;
}
.accordion__body{
	max-height: 0;
	overflow: hidden;
	transition: var(--accordion-body-transition);
}
.accordion__body--open{
	max-height: var(--accordion-body-max-height);
}
.accordion__trigger{
	margin-left: var(--accordion-trigger-margin);
}
</style>

<!-- Used in : AR, DS -->